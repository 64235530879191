import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { Article, ArticleSection } from "../../../styledComponents/article"

const WpDevelopment = ({ data, location, createCookie }) => {
    return (
      <Layout location={location}>
        <Seo 
          title="WordPress web design company -The Internet Consultancy Group" 
          description="WordPress development agency. Providing expert technical web development services. Custom, easy-to-use, performance-driven websites."
          image=""
          url=""
        />
          <Article>
            <ArticleSection>
              <p>We understand that every business has unique hosting needs. That's why we offer both shared and dedicated server options for our clients.</p>
              <p>Our shared server hosting is perfect for small to medium-sized businesses and startups. It's an affordable option that provides reliable and fast performance for website and applications. Our shared servers are equipped with the latest technology, ensuring that your website or application will run smoothly and efficiently. Plus, our team of experts monitors the servers 24/7 to ensure maximum uptime.</p>
              <p>For businesses with higher traffic or more complex needs, our dedicated server hosting is the ideal solution. With a dedicated server, you get the full power and resources of a server all to yourself, ensuring maximum performance and flexibility. This option is perfect for businesses that require maximum uptime, high traffic capacity and complete control over the server environment.</p>
              <p>Our dedicated servers are also customizable, so you can choose the specs and resources that best fit your business's needs. And with our team of experts managing and maintaining your server, you can focus on growing your business.</p>
              <p>We also offer Managed Services for both shared and dedicated servers, which includes proactive monitoring, management and support to keep your website or application running at optimal performance. With our Managed Services, you can have peace of mind knowing that your website or application is in good hands.</p>
              <p>We understand that choosing the right hosting solution can be a daunting task, that's why our team of experts is always on hand to help you choose the best option for your business. Contact us today to learn more about our shared and dedicated server hosting options and how we can help take your business to the next level.</p>

              <h2>SSL Certificates</h2>
<p>Secure your site and add trust & confidence for your visitors.</p>
<p>With a range of brands, we have the right certificate for all your site security needs</p>
              </ArticleSection>
            </Article>
      </Layout>
    )
}

export default WpDevelopment
